html,
body {
    height: 100%;
    padding: 0px;
    margin: 0px;
}

h2,
h3,
h4 {
    margin: 0px;
}

bn-book {
    display: block;
    width: 900px;
    height: 675px;
    overflow: hidden;
}

bn-page {
    display: block;
    width: 900px;
    height: 675px;
    overflow: hidden;
}
