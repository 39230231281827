.root {
    display: flex;
    justify-content: space-evenly;
    align-items: space-evenly;
    flex-direction: column;
}

.row {
    display: flex;
}

.column {
    display: flex;
    width: 100%;
}
