.root {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.termsOfServiceWrapper {
    border: 1px solid #979797;
    max-height: 120px;
    max-width: 460px;
    overflow: auto;
    text-align: justify;
    padding: 0.5rem;
}

.acceptWrapper {
    display: flex;
}

.acceptWrapper * {
    padding: 0 0.5rem;
}
